
export default {
    props: {
        image: {
            type: Object,
        },
        thumbnailOnly: {
            type: Boolean,
            default: false,
        },
        height: {
            type: String,
            default: "",
        },
        width: {
            type: String,
            default: "",
        },
    },
};
