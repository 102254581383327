
import { mapGetters } from "vuex";

export default {
    props: ["formId", "navHeight", "hideHeader"],
    data: function () {
        return { editItem: null };
    },
    computed: {
        ...mapGetters({ items: "cart/items" }),
    },
    methods: {
        edit: function (item) {
            this.editItem = item;
            let self = this;
            setTimeout(function () {
                self.$bvModal.show(self.formId);
            }, 100);
        },
    },
};
