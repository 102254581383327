
import { mapGetters } from "vuex";

export default {
    name: "pageHeader",
    mounted() {
        let height = this.$refs.navbar.$el.clientHeight;
        this.$emit("height", height);
    },
    computed: {
        ...mapGetters({ cartSize: "cart/size", items: "cart/items", orderSettings: "order-settings/data" }),
        pages() {
            let pages = [
                { title: "Home", path: "/" },
                { title: "About", path: "/about" },
                { title: "Gallery", path: "/gallery" },
                {title: "Wholesale", path: "https://www.faire.com/user/sign-up?fdb=theillegalbakerllc", external: true},
                { title: "Menu", path: "/order" },
            ];
            if (this.$store.state.auth.loggedIn) {
                pages.push({ title: "Admin", path: "/admin" });
            }
            return pages
        }
    },
};
