import { render, staticRenderFns } from "./main.vue?vue&type=template&id=52d252ce"
import script from "./main.vue?vue&type=script&lang=js"
export * from "./main.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHeader: require('/opt/build/repo/components/PageHeader.vue').default,CartSidebar: require('/opt/build/repo/components/Cart/Sidebar.vue').default,Footer: require('/opt/build/repo/components/Footer.vue').default})
