
import { mapGetters } from "vuex";
export default {
    data: function () {
        return {
            optionsId: `option-${this.item.id}`,
            optionsVisible: false,
            hovered: false,
        };
    },
    computed: {
        ...mapGetters({ menuItems: "menu-item/all", images: "image/all" }),
        item: {
            get() {
                return JSON.parse(JSON.stringify(this.cartItem));
            },
            cache: false,
        },
        menuItem() {
            if (Object.keys(this.menuItems).length) {
                const menuItem = this.menuItems[this.item.menuItem];
                if (menuItem) {
                    return menuItem
                } else {
                    this.$store.dispatch("cart/remove", this.item.id);
                }
            }
        },
        image() {
            if (this.menuItem) {
                return this.images[this.menuItem.image];
            }
        },
    },
    methods: {
        isArray(option) {
            return option instanceof Array
        },
        hoverHandler(isHovered) {
            this.hovered = isHovered;
        },
        updateQuantity(value) {
            if (!(this.item.quantity == 1 && value == -1)) {
                let payload = {
                    id: this.item.id,
                    data: {
                        quantity: this.item.quantity + value,
                    },
                };
                this.$store.dispatch("cart/update", payload);
            }
        },
        remove() {
            this.$store.dispatch("cart/remove", this.item.id);
        },
    },
    props: ["cartItem"],
};
